import {
  Box,
  Button,
  Center,
  Text,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";

import Link from "next/link";
import { ProductCard } from "../Card/ProductCard";
import ProductCardType from "../Card/ProductCardType";
import Slider from "react-slick";
import StaticUtils from "../../services/static-utils.service";
import arrayShuffle from "array-shuffle";
import { config } from "../../services/config.service";
import { productSearchService } from "../../services/graphql/product-search.service";
import router from "next/router";

const ProductCarousel = (props: any) => {
  const [cartData, setCartData] = React.useState<any>([]);
  const [productList, setProductList] = useState([]);
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
    md: false,
    sm: false,
  });
  React.useEffect(() => {
    setCartData(props.cartProductIds);
  }, [props.cartProductIds]);
  // fetch product data
  React.useEffect(() => {
    async function getProductData() {
      if (productList && productList.length) {
        return;
      }

      try {
        let response: any;
        if (props.products) {
          response = props.products;
        } else {
          response = await productSearchService.searchProduct(
            0,
            20,
            "",
            props.data.id,
            []
          );
        }
        setProductList(arrayShuffle(response?.items) || []);
      } catch (e) {}
    }
    getProductData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, props.data]);

  const sliderType: any = props.carousalType; //assign dynamic slidertype in props
  let settings: any;
  let homepageCarousalType = [
    "ImageWithTitle",
    "multiImageWithCTA",
    "singleImageWithCTA",
  ].includes(props.carousalType);
  switch (sliderType) {
    case "ImageWithTitle":
      settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1800,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: false,
            },
          },
          {
            breakpoint: 1580,
            settings: {
              slidesToShow: 3.6,
              slidesToScroll: 3.6,
              infinite: false,
            },
          },
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 3.3,
              slidesToScroll: 3.3,
              infinite: false,
            },
          },
          {
            breakpoint: 1366,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: false,
            },
          },
          {
            breakpoint: 1220,
            settings: {
              slidesToShow: 2.4,
              slidesToScroll: 2.4,
              infinite: false,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2.4,
              slidesToScroll: 2.4,
              infinite: false,
            },
          },
          {
            breakpoint: 990,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: false,
            },
          },
          {
            breakpoint: 837,
            settings: {
              infinite: false,
              slidesToShow: 3.5,
              slidesToScroll: 3.5,
              initialSlide: 0,
            },
          },
          {
            breakpoint: 768,
            settings: {
              infinite: false,
              slidesToShow: 3,
              slidesToScroll: 3,
              initialSlide: 0,
            },
          },
          {
            breakpoint: 600,
            settings: {
              infinite: false,
              slidesToShow: 2.5,
              slidesToScroll: 2.5,
              initialSlide: 0,
            },
          },
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 2.7,
              slidesToScroll: 2.7,
              initialSlide: 0,
              infinite: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2.6,
              slidesToScroll: 2.6,
              infinite: false,
            },
          },
          {
            breakpoint: 435,
            settings: {
              slidesToShow: 2.5,
              slidesToScroll: 2.5,
              initialSlide: 0,
              infinite: false,
            },
          },
          {
            breakpoint: 420,
            settings: {
              slidesToShow: 2.3,
              slidesToScroll: 2.3,
              initialSlide: 0,
              infinite: false,
            },
          },
          {
            breakpoint: 390,
            settings: {
              slidesToShow: 2.2,
              slidesToScroll: 2.2,
              infinite: false,
            },
          },
        ],
      };
      break;

    case "singleImageWithCTA":
      settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1800,
            settings: {
              slidesToShow: 4.5,
              slidesToScroll: 4.5,
              infinite: false,
            },
          },
          {
            breakpoint: 1580,
            settings: {
              slidesToShow: 4.3,
              slidesToScroll: 4.3,
              infinite: false,
            },
          },
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: false,
            },
          },
          {
            breakpoint: 1366,
            settings: {
              slidesToShow: 3.4,
              slidesToScroll: 3.4,
              infinite: false,
            },
          },
          {
            breakpoint: 1220,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: false,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2.8,
              slidesToScroll: 2.8,
              infinite: false,
            },
          },
          {
            breakpoint: 990,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: false,
            },
          },
          {
            breakpoint: 837,
            settings: {
              infinite: false,
              slidesToShow: 2.6,
              slidesToScroll: 2.6,
              initialSlide: 0,
            },
          },
          {
            breakpoint: 768,
            settings: {
              infinite: false,
              slidesToShow: 2.3,
              slidesToScroll: 2.3,
              initialSlide: 0,
            },
          },
          {
            breakpoint: 660,
            settings: {
              infinite: false,
              slidesToShow: 2.1,
              slidesToScroll: 2.1,
              initialSlide: 0,
            },
          },
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 1.9,
              slidesToScroll: 1.9,
              initialSlide: 0,
              infinite: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1.7,
              slidesToScroll: 1.7,
              infinite: false,
            },
          },
          {
            breakpoint: 435,
            settings: {
              slidesToShow: 1.7,
              slidesToScroll: 1.7,
              initialSlide: 0,
              infinite: false,
            },
          },
          {
            breakpoint: 420,
            settings: {
              slidesToShow: 1.5,
              slidesToScroll: 1.5,
              initialSlide: 0,
              infinite: false,
            },
          },
          {
            breakpoint: 390,
            settings: {
              slidesToShow: 1.5,
              slidesToScroll: 1.5,
              infinite: false,
            },
          },
        ],
      };
      break;

    case "multiImageWithCTA":
      settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 2.2,
        slidesToScroll: 2.2,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1800,
            settings: {
              slidesToShow: 2.5,
              slidesToScroll: 2.5,
              infinite: false,
            },
          },
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 2.2,
              slidesToScroll: 2.2,
              infinite: false,
            },
          },
          {
            breakpoint: 1366,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: false,
            },
          },
          {
            breakpoint: 1220,
            settings: {
              slidesToShow: 1.7,
              slidesToScroll: 1.7,
              infinite: false,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1.5,
              slidesToScroll: 1.5,
              infinite: false,
            },
          },
          {
            breakpoint: 990,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
              infinite: false,
            },
          },
          {
            breakpoint: 837,
            settings: {
              infinite: false,
              slidesToShow: 4,
              slidesToScroll: 4,
              initialSlide: 0,
            },
          },
          {
            breakpoint: 768,
            settings: {
              infinite: false,
              slidesToShow: 3.7,
              slidesToScroll: 3.7,
              initialSlide: 0,
            },
          },
          {
            breakpoint: 660,
            settings: {
              infinite: false,
              slidesToShow: 3.3,
              slidesToScroll: 3.3,
              initialSlide: 0,
            },
          },

          {
            breakpoint: 580,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              initialSlide: 0,
              infinite: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2.9,
              slidesToScroll: 2.9,
              infinite: false,
            },
          },
          {
            breakpoint: 435,
            settings: {
              slidesToShow: 2.7,
              slidesToScroll: 2.7,
              initialSlide: 0,
              infinite: false,
            },
          },
          {
            breakpoint: 420,
            settings: {
              slidesToShow: 2.5,
              slidesToScroll: 2.5,
              initialSlide: 0,
              infinite: false,
            },
          },
          {
            breakpoint: 390,
            settings: {
              slidesToShow: 2.3,
              slidesToScroll: 2.3,
              infinite: false,
            },
          },
        ],
      };
      break;

    default:
      settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 6.6,
        slidesToScroll: 5,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 5,
              infinite: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              infinite: false,
              slidesToShow: 3,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2.5,
              slidesToScroll: 2,
              infinite: false,
            },
          },
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 2.5,
              slidesToScroll: 2,
              initialSlide: 2,
              infinite: false,
            },
          },
        ],
      };
  }

  return productList && productList.length ? (
    <Box
      pl={isDesktop ? "24px" : "10px"}
      pr={isDesktop ? "24px" : "0px"}
      pt={{ base: "0" }}
      pb={{ base: "36px" }}
      backgroundColor={props.backgroundColour || "white"}
    >
      <Text
        color="#1F2D47"
        fontFamily={StaticUtils.titleFontName}
        fontWeight={{ base: "700", lg: "700" }}
        fontSize={{ base: "20", lg: "24px" }}
        mb={{ base: "2", lg: "45" }}
        mt={{
          base: "2",
          sm: "30px",
          md: "45px",
          lg: "50px",
          xl: "90px",
          "2xl": "90px",
          // base: "2", lg: "90", md: ''
        }}
        ml={{ base: "5px", lg: "40px" }}
      >
        {props?.title || props.data.name}
      </Text>
      <Slider {...settings}>
        {productList &&
          productList.map((product: any, key) => {
            return (
              <Box
                maxWidth={"278px"}
                maxHeight=""
                pl={{ sm: "5px", lg: "12px" }}
                key={key}
              >
                {[
                  "ImageWithTitle",
                  "multiImageWithCTA",
                  "singleImageWithCTA",
                ].includes(props.carousalType) ? (
                  <ProductCardType
                    cartData={cartData ? cartData : []}
                    setCartData={setCartData}
                    instock={product.inStock}
                    productId={product.productId}
                    productVariantId={product.productVariantId}
                    id={product.slug}
                    type={props.carousalType}
                    imgSrc={product.productAsset?.preview}
                    price={Math.ceil(
                      StaticUtils.formatAmount(
                        config?.showRentWithTax == "true"
                          ? product?.minRentWithTax
                          : product?.minRent
                      )
                    )}
                    productName={product.productName}
                    isAvailableForShortTermRental={
                      product.isAvailableForShortTermRental
                    }
                    minShortTermRentPrice={StaticUtils.formatAmount(
                      config?.showRentWithTax == "true"
                        ? product.minShortTermRentWithTax
                        : product.minShortTermRent
                    )}
                  />
                ) : (
                  <ProductCard
                    id={product.slug}
                    price={Math.ceil(
                      config?.showRentWithTax == "true"
                        ? product?.minRentWithTax
                        : product?.minRent
                    )}
                    name={product.productName}
                    imgSrc={product.productAsset?.preview}
                    alt={product.productName}
                  />
                )}
              </Box>
            );
          })}
      </Slider>
      {props.carousalType == "ImageWithTitle" && (
        <Center>
          <Link aria-hidden="true" href={"/search?search=;category="}>
            <Button
              aria-hidden="true"
              variant="outline"
              mt={{ base: "15px", lg: "55px" }}
              w="215px"
              h="46px"
              bgColor="white"
            >
              Browse all products
            </Button>
          </Link>
        </Center>
      )}
    </Box>
  ) : (
    <Box />
  );
};

export default ProductCarousel;
