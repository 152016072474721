import ImageWithCTA from "./ImageWithCTA";
import ImageWithTitle from "./ImageWithTitle";
import React from "react";

type ListProps = {
  cartData: any;
  setCartData: any;
  instock: any;
  productId: any;
  productVariantId: any;
  id: any;
  type: string;
  imgSrc: any;
  price: any;
  productName: any;
  isAvailableForShortTermRental: boolean;
  minShortTermRentPrice: number;
};

const ProductCardType = (props: ListProps) => {
  const {
    cartData,
    setCartData,
    instock,
    productId,
    productVariantId,
    id,
    type,
    imgSrc,
    productName,
    price,
    isAvailableForShortTermRental,
    minShortTermRentPrice,
  } = props;

  if (["singleImageWithCTA", "multiImageWithCTA"].includes(type)) {
    return (
      <ImageWithCTA
        cartData={cartData ? cartData : []}
        setCartData={setCartData}
        instock={instock}
        productId={productId}
        productVariantId={productVariantId}
        id={id}
        multiImage={type == "multiImageWithCTA"}
        imgSrc={imgSrc}
        name={productName}
        price={price}
        isAvailableForShortTermRental={isAvailableForShortTermRental}
        minShortTermRentPrice={minShortTermRentPrice}
      />
    );
  } else if (type == "ImageWithTitle") {
    return (
      <ImageWithTitle
        id={id}
        imgSrc={imgSrc}
        productName={productName}
        price={price}
      />
    );
  } else {
    return <span></span>;
  }
};
export default ProductCardType;
