import { AspectRatio, Box, Skeleton, Stack, Text, VStack, useBreakpointValue } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import Image from 'next/image';
import Link from 'next/link';
import Router from 'next/router'
import { config } from "../../services/config.service";
import moment from "moment"
import { useRouter } from 'next/router';

type ListProps = {
    id?: any;
    imgSrc: string;
    productName: string;
    price: any;
    goToLink?: any;
}

const ImageWithTitle = (props: ListProps) => {
    const { id, imgSrc, productName, price, goToLink } = props
    const imgAlt = productName.length > 30 ? productName.substring(0, 30) + "..." : productName;
    const linkUrl = goToLink != "undefined" ? goToLink : "/product/" + id;
    const striketroughPercentage: any = Number(config.priceStrikethroughPercentage);

    const isDesktop = useBreakpointValue({ base: false, lg: true });
    return (
        <>
            <Link href={linkUrl || '/'} aria-label={imgAlt}>
                <Box
                    maxW="7xl"
                    mx="auto"
                    cursor="pointer"
                    // onClick={() => Router.push("/product/" + id)}
                    borderRadius='10px'
                    ml={{ lg: "10px" }}
                >
                    <VStack backgroundColor={{ lg: 'white' }} borderRadius='10px' spacing={{ base: '3', lg: '4' }} m={{ base: "0 5px", md: "0" }} width={{ lg: '351px', base: '159px' }}>
                        <Box position="relative" borderRadius="6px" >
                            {/* <AspectRatio ratio={3 / 3}> */}

                            {isDesktop ?
                                <Image
                                    style={{ objectFit: 'cover', borderRadius: "10px 10px 0px 0px" }}
                                    width={351}
                                    height={437} alt={`${imgAlt}`}
                                    draggable="false"
                                    // fallback={<Skeleton />}
                                    src={imgSrc}
                                />
                                :
                                <Image
                                    style={{ objectFit: 'cover', borderRadius: "5px" }}
                                    width={159}
                                    height={198} alt={`${imgAlt}`}
                                    draggable="false"
                                    // fallback={<Skeleton />}
                                    src={imgSrc}
                                />}

                            {/* </AspectRatio> */}
                        </Box>
                        <Stack borderRadius="0px 0px 10px 10px" backgroundColor={{ lg: 'white' }} textAlign='left' gap='0' spacing='0' p={{ lg: '0 21px 21px', base: '0px' }} width={{ lg: '100%', base: '159px' }}>
                            <Text aria-hidden="true" color='#1F2D47' fontFamily={"Inter"} fontSize={{ lg: '20px', base: '14px' }} fontWeight='700' whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">{productName}</Text>
                            <Text aria-hidden="true" fontFamily={"Inter"} color='#E47066' fontSize={{ lg: '18px', base: '14px' }} fontWeight='400' mt='0px'>{goToLink ? price : `Starting ₹${price}/mo`}</Text>
                            {/* <Text color='#4F4F4F' as='s'
                                fontSize={{ lg: '18px', base: '14px' }}  fontWeight='500'>
                                ₹{(Number(price) + (Number(price) * (striketroughPercentage / 100))).toFixed(2)}/m
                            </Text> */}
                        </Stack>
                    </VStack>
                </Box>

            </Link>
        </>
    )
}
export default ImageWithTitle;