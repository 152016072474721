import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { StoreContext, actionTypes } from "../../context/user-context";

import Link from "next/link";
import PincodeOverlay from "./PincodeOverlay";
import { RiCheckboxCircleLine } from "react-icons/ri";
import Router from "next/router";
import StaticUtils from "../../services/static-utils.service";
import { analytics } from "../../services/analytics.service";
import { cartservice } from "../../services/graphql/cart.service";
import { config } from "../../services/config.service";
import { productDetailsService } from "../../services/graphql/product-details.service";
import cssStyle from "./listing.module.css";
import { useRouter } from "next/router";
import Image from "next/image";
import { placeorderService } from "../../services/graphql/placeOrder.service";

type ListProps = {
  cartData: any;
  setCartData: any;
  instock: any;
  productId: any;
  productVariantId: any;
  id: any;
  multiImage: boolean;
  imgSrc: any;
  name: any;
  price: any;
  isAvailableForShortTermRental: boolean;
  minShortTermRentPrice: number;
};

const ImageWithCTA = (props: ListProps) => {
  const {
    cartData,
    setCartData,
    id,
    multiImage,
    imgSrc,
    name,
    price,
    isAvailableForShortTermRental,
    minShortTermRentPrice,
  } = props;
  const [isLoading, setLoading] = React.useState(false);
  const [pinList, setPinList] = React.useState<string[]>([]);
  const { state, dispatch } = React.useContext(StoreContext);
  const toast = useToast();
  const router = useRouter();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const striketroughPercentage: any = Number(
    config.priceStrikethroughPercentage
  );

  // Repalcing Text form config file (Billed for tenure duration)
  const isBilledTenureText = config.isBilledTenureText;

  const handleCart = async () => {
    setLoading(true);

    const cart = await cartservice.getCartProducts();

    try {
      const responseProduct = await productDetailsService.getProductDetails(
        props.id
      );
      let rentPlans = responseProduct.variants
        ? JSON.parse(responseProduct.variants[0].rentalPlans)
        : [];
      let tenureMonths =
        responseProduct.variants &&
        rentPlans.plans[rentPlans.plans?.length - 1]?.tenure;

      if (cart.state === "ArrangingPayment") {
        const stateChange = await cartservice.transitionToAddingItems();
      }
      const res = await placeorderService.getActiveOrder();
      const updatedOrder = await cartservice.addProductsToCart(
        props.productVariantId,
        1,
        {
          paymentSchedule:
            res?.customFields?.paymentSchedule ??
            config.defaultPaymentSchedule?.toString(),
          purchaseType: "renting",
          rentalTenureInMonths: tenureMonths,
        }
      );
      if (updatedOrder.__typename === "InsufficientStockError") {
        setLoading(false);
      } else {
        const cart = await cartservice.getCartProducts();
        setCartData(cart.lines || []);
        dispatch({ type: actionTypes.CART_ADD_ITEM, payload: updatedOrder });
        onClose();

        isDesktop &&
          toast({
            position: "top-right",
            render: () => (
              <Stack
                bg="#FFFFFF"
                height="157px"
                w="558px"
                borderRadius="4px"
                align="center"
                justifyContent="center"
                border="0.5px solid #E47066"
              >
                <Flex>
                  <HStack>
                    <Image
                      style={{ objectFit: "contain" }}
                      src={props.imgSrc}
                      alt="no image"
                      width={67}
                      height={85}
                    />
                    <VStack w="181px" align="left" justifyContent="top">
                      <Text
                        overflow="hidden"
                        h="42px"
                        w="181px"
                        fontWeight="500"
                        fontSize="16px"
                      >
                        {props.name}
                      </Text>
                      <Spacer />
                      <HStack fontWeight="700" color="#219653" fontSize="16px">
                        <Icon as={RiCheckboxCircleLine} />
                        <Text>Added to cart</Text>
                      </HStack>
                    </VStack>
                    <VStack>
                      <Button
                        aria-hidden="true"
                        onClick={toastCheckout}
                        w="211px"
                        h="46px"
                        bg="primary"
                        borderColor="primary"
                        fontSize="16px"
                        fontWeight="700"
                        color="white"
                        variant="solid"
                      >
                        Proceed to rent
                      </Button>
                      <Button
                        aria-hidden="true"
                        onClick={toastCart}
                        w="211px"
                        h="46px"
                        color="primary"
                        borderColor="primary"
                        fontSize="16px"
                        fontWeight="700"
                        variant="outline"
                      >
                        View cart
                      </Button>
                    </VStack>
                  </HStack>
                </Flex>
              </Stack>
            ),
            duration: 4000,
            isClosable: true,
          });

        !isDesktop &&
          toast({
            position: "top",
            render: () => (
              <Stack
                bg="#FFFFFF"
                top="0"
                left="0"
                right="0"
                p={2}
                position="fixed"
                height="157px"
                w="100vw"
                borderRadius="0px"
                align="left"
                justifyContent="left"
                border="0.5px solid #E47066"
              >
                {/* <Flex> */}
                <HStack>
                  <Image
                    style={{ objectFit: "contain" }}
                    src={props.imgSrc}
                    alt="no image"
                    width={67}
                    height={85}
                  />
                  <VStack w="181px" align="left" justifyContent="center">
                    <Text
                      overflow="hidden"
                      h="46px"
                      w="67vw"
                      fontWeight="500"
                      fontSize="16px"
                    >
                      {props.name}
                    </Text>
                    <Spacer />
                    <HStack fontWeight="700" color="#219653" fontSize="16px">
                      <Icon as={RiCheckboxCircleLine} />
                      <Text>Added to cart</Text>
                    </HStack>
                  </VStack>
                </HStack>
                <HStack>
                  <Button
                    aria-hidden="true"
                    onClick={toastCheckout}
                    w="160px"
                    h="39px"
                    bg="primary"
                    borderColor="primary"
                    fontSize="16px"
                    fontWeight="700"
                    color="white"
                    variant="solid"
                  >
                    Proceed to rent
                  </Button>
                  <Button
                    aria-hidden="true"
                    onClick={toastCart}
                    w="160px"
                    h="39px"
                    color="primary"
                    borderColor="primary"
                    fontSize="16px"
                    fontWeight="700"
                    variant="outline"
                  >
                    View cart
                  </Button>
                </HStack>
                {/* </Flex> */}
              </Stack>
            ),
            duration: 4000,
            isClosable: true,
          });

        setLoading(false);
      }
      // send google event
      const productDetails = {
        product: responseProduct,
        qty: 1,
        rentalTenureInMonths: tenureMonths,
        price: props?.price,
      };
      analytics.sendAddToCartEvent(productDetails);
    } catch (e) {
      setLoading(false);
    }
  };

  const handlePincode = async () => {
    setLoading(true);
    const pinResp = await productDetailsService.getChannelPincodes(
      props?.productId
    );
    const variantChannels = pinResp?.variants[0]?.channels || [];
    let pinCodesArray = [];

    for (let i = 0; i < variantChannels.length; i++) {
      let channel = variantChannels[i];
      if (channel.customFields.deliverableZipCodes) {
        pinCodesArray.push(channel.customFields.deliverableZipCodes);
      }
    }
    let pinCodes = pinCodesArray.join(",");

    // let pinCodes = pinResp?.variants[0]?.channels[0]?.customFields?.deliverableZipCodes;
    if (pinCodes == null || pinCodes == "") {
      handleCart();
    } else {
      let pinCodesList = pinCodes?.split(",");
      setPinList(pinCodesList);
      onOpen();
      setLoading(false);
    }
  };
  const toastCheckout = () => {
    toast.closeAll();
    router.push("/checkout");
  };
  const toastCart = () => {
    toast.closeAll();

    router.push("/cart");
  };

  const findCartProduct = (pId: any): any => {
    return cartData.find((cp: any) => {
      return cp.productVariant.id == pId;
    });
  };
  const isProductInCart = (pId: any) => {
    return !!findCartProduct(pId);
  };

  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const imgAlt =
    props.name.length > 30 ? props.name.substring(0, 30) + "..." : props.name;
  return (
    <>
      <Stack
        borderRadius="10px"
        pb="15px"
        backgroundColor={multiImage ? { lg: "white" } : { base: "white" }}
        direction={multiImage ? { base: "column", lg: "row" } : "column"}
        alignItems="flex-start"
        w={
          multiImage
            ? { lg: "570px", base: "144px" }
            : { lg: "294px", base: "220px" }
        }
      >
        <HStack
          h={
            multiImage
              ? { lg: "100%", base: "220px" }
              : { lg: "350px", base: "250px" }
          }
          objectFit={"contain"}
        >
          {multiImage ? (
            <Link href={"/product/" + id} aria-label={imgAlt}>
              <VStack
                // onClick={() => Router.push("/product/" + id)}
                alignItems="flex-start"
                width={{ lg: "253px", base: "144px" }}
                height={{ lg: "100%", base: "220px" }}
              >
                <Box
                  position="relative"
                  width={{ lg: "253px", base: "144px" }}
                  height={{ lg: "158px", base: "220px" }}
                >
                  <Image
                    style={{ objectFit: "contain", borderRadius: "6px" }}
                    layout="fill"
                    alt={imgAlt}
                    src={imgSrc}
                  />
                </Box>
                {isDesktop && (
                  <HStack width="253px" maxHeight="117px">
                    <Box position="relative" width={"123px"} height={"117px"}>
                      <Image
                        style={{ objectFit: "contain", borderRadius: "6px" }}
                        layout="fill"
                        alt={imgAlt}
                        src={imgSrc}
                      />
                    </Box>
                    <Box position="relative" width={"123px"} height={"117px"}>
                      <Image
                        style={{ objectFit: "contain", borderRadius: "6px" }}
                        layout="fill"
                        alt={imgAlt}
                        src={imgSrc}
                      />
                    </Box>
                  </HStack>
                )}
              </VStack>
            </Link>
          ) : (
            <Link href={"/product/" + id} passHref>
              <Box
                position="relative"
                width={isDesktop ? "294px" : "220px"}
                height={isDesktop ? "253px" : "220px"}
                borderRadius={{ lg: "10px 10px 0px 0px", base: "5px" }}
              >
                <Image
                  style={{ objectFit: "contain", cursor: "pointer" }}
                  layout="fill"
                  alt={imgAlt}
                  src={imgSrc}
                />
              </Box>
            </Link>
          )}
        </HStack>
        <HStack>
          <VStack
            textAlign="left"
            w={multiImage ? { lg: "309px", base: "144px" } : "294px"}
            pl={multiImage ? { base: "0px", lg: "9px" } : "20px"}
            pb={multiImage ? {} : "10px"}
            alignItems="flex-start"
            height={"180px"}
          >
            <Link href={"/product/" + id}>
              {isDesktop ? (
                <Text
                  color="#1F2D47"
                  fontFamily={StaticUtils.titleFontName}
                  cursor="pointer"
                  fontSize={multiImage ? { base: "14px", lg: "16px" } : "16px"}
                  fontWeight="400"
                  lineHeight={{ base: "23px", lg: "27px" }}
                  height={
                    multiImage
                      ? { lg: "81px", base: "69px" }
                      : { lg: "86px", base: "115px" }
                  }
                  w={
                    multiImage
                      ? { lg: "246px", base: "143px" }
                      : { lg: "246px", base: "182px" }
                  }
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {name}
                </Text>
              ) : (
                <Text
                  color="#1F2D47"
                  fontFamily={StaticUtils.titleFontName}
                  cursor="pointer"
                  fontSize={multiImage ? { base: "14px", lg: "16px" } : "16px"}
                  fontWeight="400"
                  lineHeight={{ base: "23px" }}
                  display="-webkit-box"
                  sx={{
                    "-webkit-line-clamp": "2",
                    "-webkit-box-orient": "vertical",
                    "line-clamp": "2",
                    "min-height": "calc(2em * 1)",
                    "line-height": "1em",
                  }}
                  w={
                    multiImage
                      ? { lg: "246px", base: "143px" }
                      : { lg: "246px", base: "182px" }
                  }
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {name}
                </Text>
              )}
            </Link>
            <Box mt="30px">
              <Stack
                direction={{ base: "column", lg: "row" }}
                alignItems={{ base: "start", lg: "baseline" }}
                spacing={{ base: "0", lg: "11px" }}
                mb={{ base: "10px", lg: "0px" }}
              >
                <Text
                  fontFamily={StaticUtils.titleFontName}
                  color="#E47066"
                  fontSize={multiImage ? { base: "18px", lg: "24px" } : "24px"}
                  fontWeight="700"
                >
                  ₹{Math.ceil(price)}/m
                </Text>
                {config.priceStrikethroughPercentage && (
                  <Text
                    fontFamily={StaticUtils.titleFontName}
                    color="#4F4F4F"
                    as="s"
                    fontSize={
                      multiImage
                        ? { base: "14px", lg: "20px" }
                        : { base: "14px", lg: "20px" }
                    }
                    fontWeight="500"
                  >
                    ₹
                    {Math.ceil(
                      Number(
                        (
                          Number(price) +
                          Number(price) * (striketroughPercentage / 100)
                        ).toFixed(2)
                      )
                    )}
                    /m
                  </Text>
                )}
              </Stack>
              {isBilledTenureText ? (
                <Text
                  mb={{ base: "10px", lg: "0px" }}
                  lineHeight="24px"
                  color="#1F2D47"
                  fontFamily={StaticUtils.titleFontName}
                  fontSize="14px"
                  fontWeight="500"
                >
                  ({isBilledTenureText})
                </Text>
              ) : null}
              {isAvailableForShortTermRental && (
                <Text
                  fontSize={{ base: "12px", lg: "12px" }}
                  letterSpacing="0.02em"
                  fontWeight="500"
                  color={"white"}
                  backgroundColor={"#E47066"}
                  paddingInline={5}
                >
                  Short term rent from ₹{Math.ceil(minShortTermRentPrice)}/day
                </Text>
              )}
            </Box>

            {isDesktop && <Spacer />}
            <VStack style={{ margin: "0px" }}>
              <Box className={cssStyle["added-pill"]}>
                {cartData && cartData.length ? (
                  (isProductInCart(props.productVariantId) && (
                    <Box className={cssStyle["pill-text"]}>
                      {findCartProduct(props.productVariantId).quantity} ADDED
                      TO CART
                    </Box>
                  )) || <Box height="20px"></Box>
                ) : (
                  <Box height="20px"></Box>
                )}
              </Box>

              {/* {props.instock == true ? (
                                <Button aria-hidden="true" bg='#FFFFFF' fontFamily={StaticUtils.titleFontName} w={multiImage ? { base: '144px', lg: '215px' } : { base: '182.22px', lg: '215px' }} h={{ base: '45px', lg: '46px' }} disabled={isLoading || (cartData && findCartProduct(props.productVariantId) && findCartProduct(props.productVariantId).quantity >= 3)} variant="outline" onClick={handlePincode} >
                                    Add to cart
                                    {isLoading && <Spinner right={'5px'} position='absolute' />}
                                </Button>) :
                                (
                                    <Button aria-hidden="true" variant="outline2" disabled>
                                        Out of stock
                                    </Button>
                                )
                            } */}
            </VStack>
          </VStack>
        </HStack>
      </Stack>

      {!isDesktop && (
        <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>
              <VStack alignItems="flex-start" color="black" spacing={1}>
                <Text fontSize="18px" fontWeight="500">
                  Enter delivery pincode
                </Text>
                <Text fontSize="14px" fontWeight="400">
                  We need to know delivery pincode to serve you better
                </Text>
              </VStack>
            </DrawerHeader>
            <DrawerBody>
              <PincodeOverlay
                handleCart={handleCart}
                pinList={pinList}
                closeOverlay={onClose}
              />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
      {isDesktop && (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
          <ModalOverlay backdropFilter="blur(3px)" />
          <ModalContent>
            <ModalHeader>
              <VStack alignItems="flex-start" color="black" spacing={1}>
                <Text fontSize="18px" fontWeight="500">
                  Enter delivery pincode
                </Text>
                <Text fontSize="14px" fontWeight="400">
                  We need to know delivery pincode to serve you better
                </Text>
              </VStack>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <PincodeOverlay
                handleCart={handleCart}
                pinList={pinList}
                closeOverlay={onClose}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
export default ImageWithCTA;
